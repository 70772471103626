import { regular as fine_regular, vip as fine_vip } from './fine.js';
import { regular as tax_regular, vip as tax_vip } from './tax.js';
import { regular as ins_regular } from './ins.js';
import { regular as common_regular } from './common.js';
export const regular = {
    'fine': fine_regular,
    'ins': ins_regular,
    'tax': tax_regular,
    'common': common_regular,
    'marketing': [],
};
export const vip = {
    'fine': fine_vip,
    'ins': ins_regular,
    'tax': tax_vip,
    'common': common_regular,
    'marketing': [],
};