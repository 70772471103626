export const regular = [
    [
        'Полис не пришел',
        [
            [
                'Прошло 3 часа после оплаты',
                'CreateTicket'
            ],
            [
                'Не прошло 3 часа после оплаты',
                1
            ],
        ]
    ],
    [
        'Уже есть полис, но приходят уведомления о продлении',
        3
    ],
    [
        'Управление уведомлениями',
        4
    ],
    [
        'Как внести изменения в полис?',
        5
    ],
    [
        'Как добавить водителя?',
        6
    ],
    [
        'Как оформить полис?',
        7
    ],
    [
        'Нет предложений от страховых компаний',
        8
    ],
    [
        'Общие вопросы по ОСАГО',
        [
            [
                'Как проверить подлинность полиса?',
                9
            ],
            [
                'Как добавить актуальный полис в приложение?',
                10
            ],
            [
                'КАСКО от бесполисных',
                11
            ],
            [
                'Как расторгнуть договор ОСАГО?',
                12
            ],
            [
                'Что влияет на стоимость полиса?',
                13
            ],
            [
                'Что влияет на КБМ?',
                14
            ],
            [
                'В каком виде я получу полис после покупки?',
                15
            ],
            [
                'Как оформить полис на иностранные документы?',
                16
            ],
            [
                'Не получается оформить полис',
                'CreateTicket'
            ],
            [
                'Другие вопросы',
                'FaqInfo',
            ]
        ]
    ]
];
export const vip = [];