import * as VueRouter from 'vue-router';

const NotificationsLayout = () => import('@/layouts/NotificationsLayout');
const NotificationsNavigation = () => import('@/layouts/navigation/NotificationsNavigation');
const SupportLayout = () => import('@/layouts/SupportLayout');
const SupportNavigation = () => import('@/layouts/navigation/SupportNavigation');
const SolutionsNavigation = () => import('@/layouts/navigation/SolutionsNavigation');

const SupportRequest = () => import('@/pages/Support/SupportRequest');
const NewRequest = () => import('@/pages/Support/NewRequest');
const SupportMain = () => import('@/pages/Support/SupportMain');

const router = VueRouter.createRouter({
  mode: 'history',
  history: VueRouter.createWebHistory(),
  base: '/',
  routes: [
    {
      path: '/notifications',
      components: {
        default: NotificationsLayout,
        navigation: NotificationsNavigation,
      },
      name: 'notifications'
    },

    {
      path: '/support', components: {
        default: SupportLayout,
        navigation: SupportNavigation,
      },
      children: [
        { path: '', component: SupportMain, name: 'support'},
        { path: 'ticket/:id', component: SupportRequest, name: 'supportRequest'},
        { path: 'new-request', component: NewRequest, name: 'newSupportRequest' },
        { path: 'solutions', component: SolutionsNavigation, name: 'products_list' },
        { path: 'solutions/:product', component: SolutionsNavigation, name: 'product_solutions' },
        { path: 'solutions/:product/:id', component: SolutionsNavigation, name: 'solution'}
      ]
    },

    {path: '/:pathMatch(.*)*', redirect: {name: 'notifications'}}
  ]
});

export default router;
