<template>
  <template v-if="globalLoading">
    <div class="loader">
      <img
        src="@/assets/img/loader-main.svg"
        alt=""
      >
    </div>
  </template>
  <MainLayout v-else />
</template>
<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import {statService} from '@rosfines/vue-common/common/services/statService';
import supportApi from '@rosfines/vue-common/common/api/supportApi';
import config from '@/config';
import urlHelper from '@/utils/url.helper';
import finesApi from '@rosfines/vue-common/common/api/finesApi';
import store from '@/store';
import MainLayout from '@/layouts/MainLayout';

const GET = urlHelper.parseQueryString(location.href).GET;
if (GET.userId && GET.rand && GET.session) {
  const params = {response: GET};
  finesApi.updateRequestParams(params, false);
}
if (GET.channel) {
  statService.init(supportApi, config.YM_SITEKEY,{
    channel: GET.channel
  });
  store.dispatch("app/setChannel", GET.channel);
} else {
  store.dispatch("app/restoreChannel");
}

export default {
  name: 'App',
  components: {
    MainLayout,
  },
  computed: {
    ...mapState({
      globalLoading: (state) => state.globalLoading,
    }),
  },
  async mounted() {
    this.loaded();
    this.setGlobalLoading(false);
    statService.logEvent('[Ситуация] Приложение загружено');
    this.loadAppVersion();
    // this.loadFeaturesList();
    //временно(?) отключено
    this.loadNotificationsCount();
  },
  methods: {
    ...mapActions({
      loaded: 'communications/sendLoadedEvent',
      loadAppVersion: 'appVersion/loadAppVersion',
      loadFeaturesList: 'features/loadFeaturesList',
      loadNotificationsCount: 'app/loadNotificationsCount',
      isAllowReopenTicket: "support/isAllowReopenTicket",
    }),
    ...mapMutations({
      setGlobalLoading: 'setGlobalLoading',
    })
  },
}
</script>
