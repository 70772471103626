export const regular = [
    [
        'Льготы по налогам',
        [
            [
                'Как узнать, положены ли мне льготы по налогам?',
                1
            ],
            [
                'Многодетная семья',
                2
            ],
            [
                'Предпенсионер',
                3
            ],
            [
                'Пенсионер',
                [
                    [
                        'Налог на имущество',
                        4
                    ],
                    [
                        'Транспортный налог',
                        5
                    ],
                    [
                        'Земельный налог',
                        6
                    ]
                ]
            ],
            [
                'Ветеран боевых действий',
                [
                    [
                        'Налог на имущество',
                        7
                    ],
                    [
                        'Транспортный налог',
                        8
                    ],
                    [
                        'Земельный налог',
                        9
                    ]
                ]
            ],
            [
                'Инвалид',
                [
                    [
                        'Налог на имущество',
                        10
                    ],
                    [
                        'Транспортный налог',
                        11
                    ],
                    [
                        'Земельный налог',
                        12
                    ]
                ]
            ],
            [
                'Участник СВО',
                13
            ]
        ],
    ],
    [
        'Общие вопросы по ЕНП/ЕНС для физических лиц',
        [
            [
                'Не приходит налог. Где взять квитанцию?',
                14
            ],
            [
                'За что налог и что такое ЕНП?',
                15
            ],
            [
                'Почему реквизиты г. Тула?',
                16
            ],
            [
                'Что такое сальдо ЕНС?',
                17
            ],
            [
                'Не согласен с задолженностью по ЕНП',
                18
            ],
            [
                'Как оплатить налог за другого человека?',
                19
            ],
            [
                'Как оплатить налог частями?',
                20
            ]
        ]
    ],
    [
        'Вопросы по оплате и погашению',
        [
            [
                'Не моя задолженность',
                [
                    [
                        'Проверить ИНН, паспорт и СНИЛС в приложении',
                        'DocumentsCheck'
                    ],
                    [
                        'Документы указаны верно',
                        'TaxTroubleReport'
                    ]
                ]
            ],
            [
                'Налог не погашен',
                [
                    [
                        'Прошло 14 дней с момента оплаты',
                        22,
                    ],
                    [
                        'Не прошло 14 дней с момента оплаты',
                        23
                    ]
                ]
            ],
            [
                'Выросла сумма долга',
                [
                    [
                        'Причины увеличения суммы долга по ЕНП',
                        24
                    ],
                    [
                        'Причины начисления пеней',
                        25
                    ]
                ]
            ],
            [
                'За что начислена задолженность?',
                26
            ],
            [
                'Новый налог не пришел от ФНС',
                14
            ],
            [
                'Нет нового налога в приложении',
                28
            ]
        ]

    ],
    [
        'Общие вопросы по ИНН',
        [
            [
                'Как получить ИНН впервые?',
                29
            ],
            [
                'Как узнать ИНН?',
                30
            ],
            [
                'Как добавить ИНН в приложение?',
                31
            ],
            [
                'Как удалить ИНН из приложения?',
                32
            ]
        ]
    ],
    [
        'Налоговые вычеты',
        [
            [
                'Общая информация о налоговых вычетах',
                33
            ],
            [
                'Как получить вычет на ребенка?',
                34
            ],
            [
                'Как получить вычет по расходам на недвижимость?',
                35
            ],
            [
                'Налоговая декларация 3-НДФЛ',
                36
            ],
            [
                'Как получить вычет по расходам на обучение?',
                37
            ],
            [
                'Как получить вычет льготнику?',
                38
            ],
            [
                'Как получить вычет по расходам на лечение?',
                39
            ]
        ]
    ],
    [
        'Вопросы по трудовым патентам для иностранных граждан',
        [
            [
                'Как получить патент впервые?',
                40
            ],
            [
                'Как оплатить патент на работу?',
                41
            ],
            [
                'Как проверить подлинность патента?',
                42
            ],
            [
                'Как продлить патент на работу?',
                43
            ]
        ]
    ],
    [
        'Другие вопросы по налогам',
        [
            [
                'Как связаться с ФНС по вопросам своих налогов?',
                44
            ],
            [
                'Другие вопросы',
                'FaqInfo'
            ]
        ]
    ]
];
export const vip = [
    [
        'Льготы по налогам',
        [
            [
                'Как узнать, положены ли мне льготы по налогам?',
                1
            ],
            [
                'Многодетная семья',
                2
            ],
            [
                'Предпенсионер',
                3
            ],
            [
                'Пенсионер',
                [
                    [
                        'Налог на имущество',
                        4
                    ],
                    [
                        'Транспортный налог',
                        5
                    ],
                    [
                        'Земельный налог',
                        6
                    ]
                ]
            ],
            [
                'Ветеран боевых действий',
                [
                    [
                        'Налог на имущество',
                        7
                    ],
                    [
                        'Транспортный налог',
                        8
                    ],
                    [
                        'Земельный налог',
                        9
                    ]
                ]
            ],
            [
                'Инвалид',
                [
                    [
                        'Налог на имущество',
                        10
                    ],
                    [
                        'Транспортный налог',
                        11
                    ],
                    [
                        'Земельный налог',
                        12
                    ]
                ]
            ],
            [
                'Участник СВО',
                13
            ]
        ]
    ],
    [
        'Общие вопросы по ЕНП/ЕНС для физических лиц',
        [
            [
                'Не приходит налог. Где взять квитанцию?',
                14
            ],
            [
                'За что налог и что такое ЕНП?',
                15
            ],
            [
                'Почему реквизиты г. Тула?',
                16
            ],
            [
                'Что такое сальдо ЕНС?',
                17
            ],
            [
                'Не согласен с задолженностью по ЕНП',
                18
            ],
            [
                'Как оплатить налог за другого человека?',
                19
            ],
            [
                'Как оплатить налог частями?',
                20
            ]
        ]
    ],
    [
        'Вопросы по оплате и погашению ',
        [
            [
                'Не моя задолженность',
                [
                    [
                        'Проверить ИНН, паспорт и СНИЛС в приложении',
                        'DocumentsCheck'
                    ],
                    [
                        'Документы указаны верно',
                        'TaxTroubleReport'
                    ]
                ]
            ],
            [
                'Налог не погашен',
                [
                    [
                        'Оплачен в нашем сервисе',
                        [
                            [
                                'Прошло 14 дней с момента оплаты',
                                22.1
                            ],
                            [
                                'Не прошло 14 дней с момента оплаты',
                                23.1
                            ]
                        ]
                    ],
                    [
                        'Оплачен в другом сервисе',
                        [
                            [
                                'Прошло 14 дней с момента оплаты',
                                22
                            ],
                            [
                                'Не прошло 14 дней с момента оплаты',
                                23
                            ]
                        ]
                    ]
                ]
            ],
            [
                'Изменилась сумма задолженности',
                [
                    [
                        'Налог оплачен в нашем сервисе',
                        24.1
                    ],
                    [
                        'Налог оплачен в другом месте',
                        24
                    ],
                    [
                        'Причины начисления пеней',
                        25
                    ]
                ]
            ],
            [
                'За что начислена задолженность?',
                26
            ],
            [
                'Новый налог не пришел от ФНС',
                14
            ],
            [
                'Нет нового налога в приложении',
                28
            ],
            [
                'Начислены пени и не погашаются',
                [
                    [
                        'Налог оплачен в нашем сервисе',
                        [
                            [
                                'Пени оплачены',
                                'CreateTicket',
                            ],
                            [
                                'Пени не оплачены',
                                0
                            ]
                        ]
                    ],
                    [
                        'Налог оплачен в другом сервисе',
                        25
                    ]
                ]
            ],
        ]
    ],
    [
        'Общие вопросы по ИНН',
        [
            [
                'Как получить ИНН впервые?',
                29
            ],
            [
                'Как узнать ИНН?',
                30
            ],
            [
                'Как добавить ИНН в приложение?',
                31
            ],
            [
                'Как удалить ИНН из приложения?',
                32
            ]
        ]
    ],
    [
        'Налоговые вычеты',
        [
            [
                'Общая информация о налоговых вычетах',
                33
            ],
            [
                'Как получить вычет на ребенка?',
                34
            ],
            [
                'Как получить вычет по расходам на недвижимость',
                35
            ],
            [
                'Налоговая декларация 3-НДФЛ',
                36
            ],
            [
                'Как получить вычет по расходам на обучение?',
                37
            ],
            [
                'Как получить вычет льготнику?',
                38
            ],
            [
                'Как получить вычет по расходам на лечение?',
                39
            ]
        ]
    ],
    [
        'Вопросы по трудовым патентам для иностранных граждан',
        [
            [
                'Как получить патент впервые?',
                40
            ],
            [
                'Как оплатить патент на работу?',
                41
            ],
            [
                'Как проверить подлинность патента?',
                42
            ],
            [
                'Как продлить патент на работу?',
                43
            ]
        ]
    ],
    [
        'Другие вопросы по налогам',
        [
            [
                'Как связаться с ФНС по вопросам своих налогов?',
                44
            ],
            [
                'Другие вопросы',
                'CreateTicket'
            ]
        ]
    ]
];