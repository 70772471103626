export const regular = [
    [
        'Как добавить / удалить документы в приложении?',
        [
            [
                'Как добавить еще один документ?',
                1
            ],
            [
                'Как удалить документ?',
                2
            ],
            [
                'Как добавить ИНН?',
                3
            ],
            [
                'Как удалить ИНН?',
                4
            ],
            [
                'Как добавить полис ОСАГО?',
                5
            ],
        ]
    ],
    [
        'Информация о сервисе',
        [
            [
                'Общая информация о сервисе',
                6
            ],
            [
                'Чем может быть полезно приложение и сервис?',
                7
            ],
            [
                'Безопасность платежей и персональных данных',
                8
            ]
        ]
    ],
    [
        'Перенос профиля пользователя',
        [
            [
                'Перенос данных между устройствами Android',
                12
            ],
            [
                'Перенос данных между другими устройствами',
                'CreateTicket'
            ]
        ]
    ],
    [
        'Уведомления от сервиса',
        [
            [
                'Приходят письма не по моим данным',
                'CreateTicket'
            ],
            [
                'Push-уведомления',
                15
            ],
            [
                'Email-уведомления',
                14
            ],
            [
                'Уведомления по ОСАГО',
                13
            ]
        ]
    ],
    [
        'Комиссия сервиса',
        [
            [
                'Какая комиссия в сервисе?',
                17
            ],
            [
                'За что взимается комиссия?',
                18
            ]
        ]
    ],
    [
        'Бонусная система',
        19
    ],
    [
        'Интерфейс и настройка приложения',
        'FaqInfo'
    ],
    [
        'Юридическая помощь в обжаловании штрафов',
        20
    ],
    [
        'Другие вопросы',
        'FaqInfo'
    ]
];
export const vip = [];