import { createStore } from 'vuex';

import app from "@/store/app";
import appVersion from "@/store/appVersion";
import communications from "@/store/communications";
import features from "@/store/features";
import notifications from "@/store/notifications";
import support from "@/store/support";

export const store = createStore({
  modules: {
    app,
    appVersion,
    communications,
    features,
    notifications,
    support,
  },
  state() {
    return {
      globalLoading: true,
    };
  },
  mutations: {
    setGlobalLoading(state, val) {
      state.globalLoading = !!val;
    },
  },
});

export default store;
